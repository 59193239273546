<template>
    <v-card>
        <v-toolbar flat outlined>
            <v-icon @click="close()">mdi-close</v-icon>

            <v-spacer />

            <v-toolbar-title>Edit repair details</v-toolbar-title>

            <v-spacer />
            
            <!-- Removed :disabled="!isValid" - engineers want to partial save -->
            <v-btn @click="save()" small text>Save</v-btn>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <v-form
                    ref="form"
                    v-model="isValid"
                >
                    <v-row>
                        <v-text-field
                            label="Repairs completed date"
                            v-model="repairsCompletedDateInput"
                            type="date"
                            placeholder="dd/mm/yyyy"
                            :max="currentDate"
                        ></v-text-field>
                    </v-row>
                    
                    <v-row>
                        <v-select
                            label="Permanent or temporary repair?"
                            v-model="repairTypeIdLocal"
                            :items="lookups.allRepairTypes"
                            item-text="name"
                            item-value="id"
                            :rules="rules.required"
                            clearable
                        ></v-select>
                    </v-row>
                    
                    <v-row>
                        <v-textarea
                            label="Repair details"
                            v-model="repairDetailsLocal"
                            :rules="rules.required"
                            placeholder="Give details of the extent of the repair undertaken"
                        ></v-textarea>
                    </v-row>

                </v-form>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import instructionsAgent from "../instructionsAgent.js";
import optionSetAgent from "../../Ods/optionSetAgent.js";

export default {
    props: {
        id: String,
        repairsCompletedDate: String,   // "DD/MM/YYYY"
        repairTypeId: Number,
        repairDetails: String,
    },
    
    data: function () {
        return {
            repairsCompletedDateLocal: this.repairsCompletedDate,
            repairsCompletedDateFormat: "DD/MM/YYYY",
            repairTypeIdLocal: this.repairTypeId,
            repairDetailsLocal: this.repairDetails,

            currentDate: this.$moment().format('YYYY-MM-DD'),

            lookups: {
                allRepairTypes: [],
            },

            isValid: true,

            rules: {
                required: [v => !!v || "Required"],
            }
        };
    },
    
    computed: {
        repairsCompletedDateInput: {
            get: function() {
                return this.repairsCompletedDateLocal
                    ? this.$moment(this.repairsCompletedDateLocal, this.repairsCompletedDateFormat).format("YYYY-MM-DD")
                    : null;
            },
            set: function(newValue) {
                if (newValue)
                    this.repairsCompletedDateLocal = this.$moment(newValue).format(this.repairsCompletedDateFormat);
            }
        }
    },

    mounted: function () {
        var l = this.$coreUi.loading();

        optionSetAgent.getOptionSetValues("vm-form-repair-type").then(data => {
            this.lookups.allRepairTypes = data.optionSetValueProperties;

            if (this.lookups.allRepairTypes.every(x => x.id !== this.repairTypeIdLocal))
                this.repairTypeIdLocal = null;

        }).then(() => {

            l.dismiss();
            this.refreshValidation();

        }).catch(console.error);
    },

    methods: {

        refreshValidation () {
            this.$nextTick(() => {
                this.$refs.form.validate();
            });
        },

        save () {
            var l = this.$coreUi.loading();
            
            // Load workItem
            instructionsAgent.getInstruction(this.id).then((result) => {

                // Set fields and save
                result.aspectData.formBuilder.formData["vm-repair-details-repair-completed-date"] = this.repairsCompletedDateLocal;
                result.aspectData.formBuilder.formData["vm-repair-details-repair-type-lov"] = this.repairTypeIdLocal;
                result.aspectData.formBuilder.formData["vm-repair-details-repair-details-text"] = this.repairDetailsLocal;
                
                // Don't invoke these aspect savers (they could fail)
                delete result.aspectData.odsEntityPicker;
                delete result.aspectData.instructionWorkTypeDetails;
                delete result.aspectData.instructionB2BDetails;
                delete result.aspectData.locationCoordinate;
                
                instructionsAgent.saveInstruction(this.id, result).then(() => {

                    l.dismiss();
                    this.close(true);

                }).catch(console.error);

            }).catch(console.error);
        },

        close (result) {
            this.$emit("close", result);
        }
    },
};
</script>

<style>
.theme--light.v-sheet .tiptap-vuetify-editor > .v-card {
    margin: 0;
}
</style>